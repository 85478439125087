<template>
  <div class="animate__animated animate__fadeIn">
    <top-bar>
      <div class="top-box">
        <img :src="logoImg" class="logo" />
        <div class="menus-box" :style="fontColor">
          <div
            class="menus"
            v-for="(m, mi) in menus"
            :style="active == m.path ? { fontWeight: 600 } : ''"
            :key="mi"
            @mouseover="changeOver(m)"
            :class="m.hover ? 'hover-bg' : ''"
            @mouseleave="mouseLeave(m)"
            @click="changePath(m)"
          >
            {{ m.name }}
            <div class="dt-line" v-if="active == m.path" :style="dtBg"></div>
            <div class="se-me hover-bg" v-if="m.hover">
              <div
                class="child"
                :style="fontColor"
                v-for="(c, ci) in m.child"
                :key="ci"
                @click.stop="changePath(c)"
              >
                {{ c.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </top-bar>
    <div class="project-box">
      <div class="title">核心技术</div>
			<div class="tb">Core Technology</div>
    </div>
    <div class="t-box">
      <div class="c-box">
        Core Technology
        <div class="c-ti">核心技术</div>
      </div>
    </div>
    <!-- <div class="chos-box">
			<div class="scrol-box">
				<div class="t1" :class="chosBar == 0 ? 'active-t' : ''" @click="changeTab(0)">低碳的混凝土养护技术</div>
				<div class="t2"  :class="chosBar == 1 ? 'active-t' : ''" @click="changeTab(1)">低碳的混凝土原料改良技术</div>
				<div class="dt-line" :class="chosBar == 0 ? 'l-a' : 'r-a'"></div>
			</div>
		</div> -->
    <div class="msg-box">
      <div class="msg">
        <div class="book-title">CO₂矿化养护混凝土技术原理</div>
        <div class="msg-ti">
          众所周知，混凝土材料中的碱性组分在自然条件下可以与大气中0.04%浓度的CO₂进行化学反应，但过程非常缓慢，在其生命周期内碳酸化深部一般不超过10毫米。
        </div>
		<div class="msg-ti">
          CO₂矿化养护混凝土技术正是通过高分压力CO₂（一般为气体）与预养护或早期水化成型后的混凝土中胶凝成分和其他碱性钙、镁组分之间的矿化反应，在混凝土内部孔隙和界面结构处形成碳酸盐产物，并通过填充效应、界面过渡区消除效应和产物层效应等实现混凝土的强度和耐久性改善。
        </div>
		<img src="../static/project/t1.png" class="msg-img">
		<div class="msg-ti">
          我们设计并开发的CCUS-CO₂矿化养护一体化体系标准化设备，利用捕获工业排放的CO₂并应用于生产混凝土的养护环节，整个体系实现了CO₂闭环利用，目前的CO₂转化率在98%左右，几乎没有二次排放，并可将CO₂高效封存。
        </div>
		<div class="book-title">CO₂矿化养护混凝土技术的工艺特点</div>
		<img src="../static/project/t2.png" class="msg-img">
		<div class="book-title-small">（传统混凝土制造与新型低碳混凝土制造）</div>
        <div class="msg-ti">
          我们的技术能够替代传统蒸压养护工艺，显著降低了养护能耗与碳排放。采用釜式反应器进行CO₂和混凝土的气固反应过程，在1bar-15bar区间压力和常温下进行恒压快速反应，2小时完成养护过程，大大节约了养护成本，同时低碳效益优势明显。
        </div>
		<div class="msg-ti">
         在原材料方面，产品固废组分高于90%，形成了基于多种工业固废（钢渣、电石渣、粉煤灰等）的新型混凝土配方，传统波特兰水泥替代率达15%以上，高效实现碳封存的同时大量消纳工业固废。
        </div>
		<div class="book-title">CO₂矿化养护混凝土核心优势</div>
        <div class="msg-ti-left">
         （1）通过CO₂养护轻质混凝土预制件技术，可实现混凝土预制件全生命周期比现有产品减少碳排放80%；
        </div>
		<div class="msg-ti-left">
         （2）90%的固体原材料来自当地的工业固废，降低原材料成本；
        </div>
		<div class="msg-ti-left">
         （3）CO₂平均吸收率98%以上，净封存率达到80%以上；
        </div>
		<div class="msg-ti-left">
         （4）低碳混凝土预制件外观、尺寸、抗折强度、抗压强度、抗冻性、放射性、吸水率等指标全部符合国标要求。
        </div>
      </div>
    </div>
    <div style="width: 100%; margin-top: 251px">
      <footer-bar path="Technology"></footer-bar>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/header-bar.vue";
import utils from "@/utils/common.js";
import footerBar from "@/components/footer-bar.vue";
export default {
  components: {
    topBar,
    footerBar,
  },
  data() {
    return {
      name: "solution",
      menus: utils.menus,
      active: "solution",
      swiperIndex: 0,
      chosBar: 0,
    };
  },
  computed: {
    logoImg() {
      // console.log(this.swiperIndex,'lll')
      if (this.swiperIndex == 0) {
        return require("../static/wlogo.png");
      } else if (this.swiperIndex == 1) {
        return require("../static/home/logo2.png");
      } else if (this.swiperIndex == 2) {
        return require("../static/wlogo.png");
      } else if (this.swiperIndex == 3) {
        return require("../static/home/logo2.png");
      } else if (this.swiperIndex == 4) {
        return require("../static/home/logo2.png");
      } else if (this.swiperIndex == 5) {
        return require("../static/wlogo.png");
      } else if (this.swiperIndex == 6) {
        return require("../static/home/logo2.png");
      }
    },
    fontColor() {
      if (this.swiperIndex == 0) {
        return {
          color: "#fff",
        };
      } else if (this.swiperIndex == 1) {
        return {
          color: "#333",
        };
      } else if (this.swiperIndex == 2) {
        return {
          color: "#fff",
        };
      } else if (this.swiperIndex == 3) {
        return {
          color: "#333",
        };
      } else if (this.swiperIndex == 4) {
        return {
          color: "#333",
        };
      } else if (this.swiperIndex == 5) {
        return {
          color: "#fff",
        };
      } else if (this.swiperIndex == 6) {
        return {
          color: "#333",
        };
      }
    },
    dtBg() {
      if (this.swiperIndex == 0) {
        return {
          background: "#fff",
        };
      } else if (this.swiperIndex == 1) {
        return {
          background: "#333",
        };
      } else if (this.swiperIndex == 2) {
        return {
          background: "#fff",
        };
      } else if (this.swiperIndex == 3) {
        return {
          background: "#333",
        };
      } else if (this.swiperIndex == 4) {
        return {
          background: "#333",
        };
      } else if (this.swiperIndex == 5) {
        return {
          background: "#fff",
        };
      } else if (this.swiperIndex == 6) {
        return {
          background: "#333",
        };
      }
    },
  },
  methods: {
    changeOver(m) {
      // console.log(m)
      if (m.path == "solution") {
        m.hover = true;
        this.active = m.path;
      }
    },
    mouseLeave(m) {
      this.menus.forEach((item) => (item.hover = false));
      this.active = this.name;
    },
    changePath(item) {
      if (item.path != this.name) {
        this.$router.push({
          name: item.path,
        });
      }
    },
    changeTab(num) {
      this.chosBar = num;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // background: #eeeeee;
  margin: 0;
  padding: 0;
}
.active-h {
  font-weight: 600;
}
.top-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: #007AFF;
  .logo {
    width: 42px;
    height: 47px;
    margin-left: 11.25rem;
  }
  .menus-box {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 197px;
    position: relative;
  }
  .menus {
    height: 100%;
    width: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin-right: 60px;
    font-size: 14px;
    font-weight: 400;

    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    .dt-line {
      width: 20px;
      height: 4px;
      // background: #FFFFFF;
      position: absolute;
      bottom: 16px;
    }
    .se-me {
      position: absolute;
      width: 114px;
      height: 108px;
      bottom: -108px;
    }
    .child {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      // color: #FFFFFF;
    }
  }
}

.project-box {
  width: 100%;
  height: 400px;
  // background: rgba(0, 0, 0, 0.5);
  background-image: url(../static/project/jishu.png);
  position: relative;
  background-size: 100% 100%;
  padding-top: 182px;
  box-sizing: border-box;
  .bg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  .title {
    font-size: 48px;
    color: #ffffff;
    margin-left: 175px;
  }
  .tb {
    font-size: 28px;
    font-weight: 400;
    color: #cccccc;
    margin-top: 12px;
    margin-left: 175px;
  }
}

.t-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 80px;
  .c-box {
    width: 500px;
    height: 58px;
    font-size: 58px;
    font-weight: bold;
    color: #f7f7f7;
    position: relative;
    .c-ti {
      font-size: 32px;
      font-weight: 600;
      color: #1a1a1a;
      position: absolute;
      bottom: 0;
      left: 35%;
    }
  }
}

.r-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .fb {
    width: 1080px;
    height: 323px;
    background: #ffffff;
    box-shadow: 2px 2px 20px 7px rgba(0, 0, 0, 0.05);
    display: flex;

    justify-content: space-between;
    .left-img {
      width: 536px;
      height: 323px;
    }
    .right-box {
      width: 536px;
      height: 323px;
      padding: 40px 0 0 40px;
      // align-items: flex-start;
      // justify-content: flex-start;
      box-sizing: border-box;
      .tt {
        font-size: 24px;
        font-weight: 500;
        color: #1a1a1a;
      }
      .cc {
        width: 448px;
        height: 112px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-top: 24px;
      }
      .btn {
        width: 134px;
        height: 50px;
        background: #075ad3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        border: 0;
        margin: 0;
        margin-left: auto;
        margin-right: 40px;
        color: #ffffff;
        img {
          width: 18px;
          height: 18px;
          margin-left: 6px;
        }
      }
    }
  }
}
.new-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  .c-box {
    width: 1087px;
    display: flex;
    flex-wrap: wrap;
    // background: #000;
    .new {
      width: 342px;
      height: 375px;
      background: #ffffff;
      box-shadow: 2px 2px 13px 5px rgba(0, 0, 0, 0.04);
      margin-bottom: 28px;
      .img {
        width: 342px;
        height: 202px;
      }
      .tr {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 18px;
        font-weight: 500;
        color: #1a1a1a;
        margin-top: 20px;
      }
      .cr {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-top: 20px;
      }
    }
  }
}

.chos-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .scrol-box {
    width: 1082px;
    font-size: 20px;
    font-weight: 500;
    color: #4d4d4d;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #f2f2f2;
    position: relative;
    .t1 {
      margin-right: 90px;
      cursor: pointer;
    }
    .t2 {
      cursor: pointer;
    }
    .active-t {
      color: #075ad3;
    }
    .dt-line {
      position: absolute;
      width: 55px;
      height: 2px;
      background: #075ad3;
      bottom: 0;
    }
    .l-a {
      left: 360px;
    }
    .r-a {
      left: 650px;
    }
  }
}
.msg-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  .book-title {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 50px !important;
	margin-top: 50px !important;
  }
  .book-title-small {
    font-size: 10px;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 50px !important;
  }
  .msg {
    width: 882px;
    margin-bottom: 24px;
    .msg-ti {
      font-size: 16px;
      text-indent: 25px;
      font-weight: 400;
      color: #666666;
      line-height: 180%;
    }
	.msg-ti-left {
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      margin-bottom: 6px !important;
    }
    .msg-img {
      width: 750px;
      height: 100%;
      margin-top: 24px;
	  margin-left: 40px; 
    }
  }
}
</style>
